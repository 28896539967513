<template>
  <MainLayout>
    <template v-slot:navbar-btn-wrap> </template>
    <template v-slot:default>
      <DeletePopup
        title="администраторов"
        :name="nameAdmin"
        :dialog="dialog"
        @closeDeletePopup="closeDeletePopup"
        :delete-function="deleteAdmin"
      />
      <TableFilters :queries="queries">
        <template v-slot:filter-title>
          <span v-if="sourceObject.count">
            <span class="title-filter">Всего:</span>{{ sourceObject.count }}
            <!--            <span>администраторов</span>-->
          </span>
        </template>
        <template v-slot:add-button>
          <router-link
            to="createAdminOppen"
            class="btn btn-add btn_color_green"
          >
            Добавить администратора
          </router-link>
        </template>
      </TableFilters>
      <TableLayout
        disable-router
        :loading="isDateLoaded"
        :table-data="sourceObject.results"
        :header="header"
        :source-object="sourceObject"
        delete-button-direction-start
      >
        <template slot-scope="{ itemFather }">
          <button
            @click="clickDelete(itemFather)"
            class="form__btn-delete btn btn-delete"
            style="background-color: #eb5c6d"
          ></button>
        </template>
      </TableLayout>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import DeletePopup from "@/components/popup/DeletePopupCommon";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

import _ from "lodash";
import phoneFilter from "@/helpers/filters/phoneFilter";

export default {
  name: "AdminsOppenPage",
  data() {
    return {
      queries: query.adminsOppen,
      dialog: false, // диалоговое окно popup удаления
      id: "", // id пользователя
      nameAdmin: "" //Имя пользователя
    };
  },
  components: { TableFilters, TableLayout, MainLayout, DeletePopup },
  computed: {
    sourceObject() {
      return this.$store.getters.getPageAdminsList;
    },
    urlQuery() {
      return {
        query: this.$route.query
      };
    },

    header() {
      return [
        {
          text: "ID",
          value: "id",
          sort: true
        },
        {
          text: "Телефон",
          renderFunction: value => phoneFilter.execute(value?.phone_number),
          sort: true
        },
        {
          text: "ФИО",
          // value: "full_name",
          renderFunction: value => value.full_name
        },
        {
          text: "Регистрация",
          value: "is_phone_confirmed",
          renderFunction: value =>
            `<p style='
        ${
          value.is_phone_confirmed === true
            ? "color: #95C23D!important;"
            : "color: #EB5C6D!important;"
        }'
      >${
        value.is_phone_confirmed === true
          ? "Зарегистрирован"
          : "Не зарегистрирован"
      }</p>`,
          sort: true
        }
      ];
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: {
        ...this.urlQuery.query
        // type: this.type
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageAdminsList", { group_id: 1 }).finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        query: {
          ...to.query
          // type: this.type
        }
      });

      this.isDateLoaded = true;
      this.$store
        .dispatch("fetchPageAdminsList", { group_id: 1 })
        .finally(() => {
          this.isDateLoaded = false;
        });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearAdminsPage").then(() => next());
  },
  methods: {
    closeDeletePopup(data) {
      this.dialog = data;
    },

    clickDelete(obj) {
      this.id = obj.id;
      let user = _.head(_.filter(this.sourceObject.results, { id: obj.id }));
      this.nameAdmin = user.full_name;
      this.dialog = true;
    },

    deleteAdmin() {
      let id = this.id;
      this.isDateLoaded = true;
      this.$store
        .dispatch("deleteAdminFromGroup", { id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.admin)
          );
        })
        .finally(() => {
          this.$store
            .dispatch("fetchPageAdminsList", { group_id: 1 })
            .finally(() => {
              this.isDateLoaded = false;
            });
        });
    }
  }
};
</script>

<style scoped></style>
